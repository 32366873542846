@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Cardo:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-size:100%;
  font: inherit;
  vertical-align: baseline;
  background-color: var(--info-color);
  color: #222;
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --font-family-cardo: 'Cardo', serif;
  --font-family-Inter: 'Inter', sans-serif;
  --blue-color: #4766ee;
  --black-color: #000000;
  --gray-color: #a2a2a2;
  --green-color: #4caf50;
  --yellow-color: #ff9800;
  --red-color: #f44336;
  --info-color: #f7f7ff;
  --light-color: #ffffff;
}
